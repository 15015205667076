// page component
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { ErrorPage404 } from "@/components/pages/404/ErrorPage404";

export default ErrorPage404;

export const getStaticProps: GetStaticProps = async context => {
  return {
    props: {
      ...(await serverSideTranslations((context?.locale as string) || "en", [
        "common",
        "error-page"
      ]))
    }
  };
};
