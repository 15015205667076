import React from "react";

import { useTranslation } from "next-i18next";
import Head from "next/head";
import { theme } from "~styles/theme/main/theme";

import DefaultLink from "@/components/elements/link/DefaultLink";
import Layout from "@/components/layouts/layout/Layout";
import { Box, Typography } from "@mui/material";

export const ErrorPage404 = () => {
  const { t } = useTranslation("error-page");
  return (
    <Layout noFooter noNavBar>
      <Head>
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Head>
      <Box
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}>
        <Typography
          color={"pink.main"}
          sx={{ mb: -3 }}
          variant={"h2"}
          fontWeight={600}
          textTransform={"uppercase"}
          fontSize={16}>
          {t("error")}
        </Typography>
        <Typography
          color={"pink.main"}
          variant={"h2"}
          fontWeight={600}
          fontSize={90}>
          404
        </Typography>
        <Typography
          variant={"h2"}
          fontWeight={500}
          textTransform={"uppercase"}
          fontSize={16}>
          {t("not-found")}
        </Typography>
        <DefaultLink
          sx={{ my: 4 }}
          textTransform={"uppercase"}
          color={"orange.main"}
          underline={"hover"}
          href={"/"}>
          {t("home-link")}
        </DefaultLink>
      </Box>
    </Layout>
  );
};
